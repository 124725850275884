import Cookies from 'js-cookie'
import { ETokenName } from '@/ajax/ISSOConfig';

// 验证是否需要跳转到登录页面，如果未登录则跳转
export function tryGo2Login() {
  !isLogin() && go2Login();
}

// 跳转到登录页面
export function go2Login() {
  let url = window.location.href;
  if (url.indexOf('/details?id=') !== -1) {
    // 如果是详情页面，则重定向到列表页面
    url = url.split('/details?id=')[0];
  }
  let targetURL = `${process.env.VUE_APP_LOGIN_ADDRESS}?redirect_uri=${encodeURIComponent(url)}&system_code=${process.env.VUE_APP_SYSTEM_CODE}`;
  window.location.href = targetURL;
}

// 判断是否已经登录
export function isLogin() {
  return !!localStorage.getItem('usToken');
}

// 退出登录
export function logout() {
  Cookies.remove(ETokenName.authorizationKey);
  localStorage.removeItem('usToken');
  localStorage.removeItem('-USER-SETTINGS');
  localStorage.removeItem('-USER-BUTTON-PERMISSIONS');
  localStorage.removeItem('account');
  localStorage.removeItem('currentOrg');
  localStorage.removeItem('customziedConf');

  sessionStorage.removeItem('currentOrgsTree');
  go2Login();
}

// 当前用户是否是dbs用户
export function getIsDbsRole() {
  const userSettings = localStorage.getItem('-USER-SETTINGS');
  const userGroups = userSettings ? JSON.parse(userSettings).user_profile?.user_groups || [] : [];
  for(let i = 0; i < userGroups.length; i++) {
    if (userGroups[i].code === 'DBS') {
      return true;
    }
  }
  return false; 
}

// 判断对应用户id是不是自己
export function getIsMine(userId) {
  const userSettings = localStorage.getItem('-USER-SETTINGS');
  const user = userSettings ? JSON.parse(userSettings).user_profile?.user || [] : [];
  return user && user.user_id === userId;
}

// 检测按钮资源是否有权限
export function authed(authKey){
    let btnsStr = localStorage.getItem('-USER-BUTTON-PERMISSIONS');
    const buttons = btnsStr ? JSON.parse(btnsStr) : [];
	return !!authKey && !!buttons && buttons.includes(authKey)
}